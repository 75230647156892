@font-face {
  font-family: "NeueHaasUnica";
  src: url("./assets/NeueHaasUnicaPro-Regular.woff") format("woff"),
    url("./assets/NeueHaasUnicaPro-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NeueHaasUnicaBold";
  src: url("./assets/NeueHaasUnicaPro-Bold.woff") format("woff"),
    url("./assets/NeueHaasUnicaPro-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "SometimesTimes";
  src: url("./assets/SometimesTimes-Regular.woff") format("woff"),
    url("./assets/SometimesTimes-Regular.woff2") format("woff2");
}

body {
  margin: 0;
  font-family: "NeueHaasUnica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ccc;
}

strong {
  font-family: "NeueHaasUnicaBold", sans-serif;
  font-weight: normal;
}

* {
  box-sizing: border-box;
}

a,
a:active,
a:visited {
  color: #333;
}

body,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;
}
